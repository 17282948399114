import { differenceInSeconds } from "date-fns";
import React from "react";

export type SessionTimerProps = {
  until: Date;
  totalTimeInSeconds?: number;
};

export const SessionTimer: React.FC<SessionTimerProps> = ({
  until,
  totalTimeInSeconds = 300,
}) => {
  const size = 200;
  const strokeWidth = 20;
  const radius = size / 2 - 2 * strokeWidth;
  const circumference = radius * 2 * Math.PI;

  const [counter, setCounter] = React.useState(
    differenceInSeconds(until, new Date().getTime())
  );

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      style={{ width: "100%", height: "100%" }}
    >
      <circle
        stroke={counter > 60 ? "rgb(156, 204, 61)" : "#dc3545"}
        strokeWidth={`${strokeWidth}`}
        fill="transparent"
        r={`${radius}`}
        cx={`${size / 2}`}
        cy={`${size / 2}`}
      />
      <circle
        style={{
          transition: "0.35s stroke-dashoffset",
          transform: "rotate(-90deg)",
          transformOrigin: "50% 50%",
        }}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={setDashoffset(
          counter,
          totalTimeInSeconds,
          circumference
        )}
        stroke="#fff"
        strokeWidth={`${strokeWidth}`}
        fill="transparent"
        r={`${radius}`}
        cx={`${size / 2}`}
        cy={`${size / 2}`}
      />
      <text
        x={`${size / 2}`}
        y={`${size / 1.8}`}
        textAnchor="middle"
        fill="var(--primary)"
        style={{
          fontSize: `${size / 3.6}px`,
          fontFamily: "'Titillium Web', sans-serif",
        }}
      >
        {counter > 60 ? Math.floor(counter / 60) : counter}
      </text>
      <text
        x={`${size / 2}`}
        y={`${size / 1.45}`}
        textAnchor="middle"
        fill="var(--primary)"
        style={{
          fontSize: `${size / 9}px`,
          fontFamily: "'Titillium Web', sans-serif",
        }}
      >
        {counter > 60 ? "min" : "sec"}
      </text>
    </svg>
  );
};

function setDashoffset(
  counter: number,
  totalTimeInSeconds: number,
  circumference: number
) {
  const secondsLeft = counter;
  const secondsTotal = totalTimeInSeconds;
  const percent = (secondsLeft / secondsTotal) * 100;
  const offset = (percent / 100) * circumference;
  return offset;
}
