import React, { CSSProperties } from "react";
import { ReactComponent as LeanscopeLogo } from "../assets/Logo.LeanScopeWhite.svg";

export type PersonaInfoProps = {
  apiUrl: string;
  sessionId: string;
};

export const PersonaInfo: React.FC<PersonaInfoProps> = ({
  apiUrl,
  sessionId,
}) => {
  const posterStyle: CSSProperties = {
    maxWidth: "100%",
    maxHeight: "100%",
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <LeanscopeLogo
        onClick={() => window.open("https://app.leanscope.io", "_blank")}
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          height: "100px",
          padding: "20px",
          cursor: "pointer",
        }}
      />
      <img
        src={`${apiUrl}/poster/${sessionId}`}
        style={posterStyle}
        alt="url"
      />
    </div>
  );
};
