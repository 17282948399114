export const accumulateSessionPersonaPlayerTypesMutation = (
  playerTypeFormUid: string,
  typeformResponseId: string,
  sessionId: string
) => `
  mutation{
    accumulateSessionPersonaPlayerTypesWithTypformResponse(
      playerTypeFormUid: "${playerTypeFormUid}",
      typeformResponseId: "${typeformResponseId}",
      personaSessionId: "${sessionId}"
    ) {
        title
        weight
    }
  }
`;
