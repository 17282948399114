export const playertypesFromTypeformQuery = (
  playerTypeFormUid: string,
  typeformResponseId: string
) => `
  query {
    getPlayerTypesFromTypeformResponse(
      playerTypeFormUid: "${playerTypeFormUid}"
      typeformResponseId: "${typeformResponseId}"
    ) {
      title
      weight
    }
  }
`;
