import React, { useState } from "react";
import "./App.css";
import "./components/PlayerTypesSurvey";
import { SessionTimer } from "./components/SessionTimer";
import { isPast } from "date-fns";
import { ApolloProvider, useQuery } from "@apollo/client/react";
import { GraphQLClient } from "./api/graph-ql.client";
import { PersonaInfo } from "./components/PersonaInfo";
import { BrowserRouter, Route, useLocation, useParams } from "react-router-dom";
import { PlayerTypesChart } from "./components/PlayerTypesChart";
import { gql } from "@apollo/client";
import { isMobileDevice } from "./helper/device-check";
import { personaBySessionIdQuery } from "./api/queries/persona-poster";
import { Language, PersonaBySession, PlayerType } from "./types";
import { PlayerTypesSurvey } from "./components/PlayerTypesSurvey";
import { SessionNotFound } from "./components/SessionNotFound";
import { ReactComponent as LeanscopeLogo } from "./assets/Logo.LeanScopeWhite.svg";

const POLL_URL =
  process.env.REACT_APP_MEDIA_API_URL ?? "https://dev.api.leanscope.io/poll";

const Launch: React.FC = () => {
  let { sessionId } = useParams<{ sessionId: string }>();

  // Query Parameter `lang` is optional and defaults to `en` if not set or not found.
  // E.g.
  // - host/{sessionId}?lang=en
  // - host/{sessionId}
  // - host/?lang=de
  // - host
  const languageQueryParameter = new URLSearchParams(
    useLocation()?.search
  )?.get("lang");
  const language: Language =
    languageQueryParameter === "de" ? Language.DE : Language.EN;

  const {
    data: personaSessionInformationResponse,
    loading,
    error,
  } = useQuery<PersonaBySession>(gql(personaBySessionIdQuery(sessionId)));

  const [playerTypeResults, updatePlayerTypesResults] = useState<
    PlayerType[] | undefined
  >();

  const graphData = [
    playerTypeResults?.map((playerType) => {
      return {
        axis: playerType.title,
        value: playerType.weight,
      };
    }) ?? [],
  ];

  if (isMobileDevice() || sessionId === undefined) {
    return SimpleView(
      language,
      sessionId,
      updatePlayerTypesResults,
      playerTypeResults,
      graphData
    );
  } else {
    if (loading) {
      return <p>Loading...</p>;
    } else if (error || !personaSessionInformationResponse) {
      if (error?.message.includes("expired")) {
        return <SessionNotFound sessionId={sessionId} />;
      } else {
        return <SessionNotFound />;
      }
    } else {
      const validUntil = new Date(
        personaSessionInformationResponse.personaBySession.startTime +
          personaSessionInformationResponse?.personaBySession
            .durationInSeconds *
            1000
      );

      console.log("validUntil: ", validUntil);
      console.log(
        "PersonaSessionInformation: ",
        personaSessionInformationResponse
      );

      if (isPast(validUntil)) {
        return <SessionNotFound sessionId={sessionId} />;
      }

      return (
        <div className="app-container">
          <div className="poster-and-timer">
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PersonaInfo apiUrl={POLL_URL} sessionId={sessionId} />
            </div>
            <div className="session-timer">
              <SessionTimer
                until={validUntil}
                totalTimeInSeconds={
                  personaSessionInformationResponse.personaBySession
                    .durationInSeconds
                }
              ></SessionTimer>
            </div>
          </div>
          <div className="survey">
            <PlayerTypesSurvey
              language={language}
              sessionId={sessionId}
              onSubmit={(playerTypes) => updatePlayerTypesResults(playerTypes)}
              onError={() => updatePlayerTypesResults(undefined)}
            />
            {playerTypeResults !== undefined && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundColor: "#e29a2a",
                  position: "absolute",
                  zIndex: 100000,
                }}
              >
                <PlayerTypesChart language={language} data={graphData} />
              </div>
            )}
          </div>
        </div>
      );
    }
  }
};

function SimpleView(
  language: Language,
  sessionId: string,
  updatePlayerTypesResults: React.Dispatch<
    React.SetStateAction<PlayerType[] | undefined>
  >,
  playerTypeResults: PlayerType[] | undefined,
  graphData: { axis: string; value: number }[][]
): React.ReactElement<any, any> | null {
  return (
    <>
      <PlayerTypesSurvey
        language={language}
        sessionId={sessionId}
        onSubmit={(playerTypes) => updatePlayerTypesResults(playerTypes)}
        onError={() => updatePlayerTypesResults(undefined)}
      />
      {playerTypeResults !== undefined && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#e29a2a",
            position: "absolute",
            zIndex: 100000,
          }}
        >
          <div
            style={{
              height: "65%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PlayerTypesChart language={language} data={graphData} />
          </div>
          <LeanscopeLogo
            style={{ padding: "0 30px 50px", width: "50%", cursor: "pointer" }}
            onClick={() => window.open("https://app.leanscope.io", "_blank")}
          />
        </div>
      )}
    </>
  );
}

function App() {
  return (
    <ApolloProvider client={GraphQLClient}>
      <BrowserRouter>
        {/* Hint: See query parameters in Launch component */}
        <Route path="/:sessionId?" component={Launch}></Route>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
