import React from "react";
import { ReactComponent as LeanscopeLogo } from "../assets/Logo.LeanScopeWhite.svg";

export const SessionNotFound: React.FC<{ sessionId?: string }> = ({
  sessionId,
}) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        background:
          "radial-gradient(at top left, #322ba1 15%, #2a2dbd 46%, #12145f 100%)",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <LeanscopeLogo
        onClick={() => window.open("https://app.leanscope.io", "_blank")}
        style={{ height: "100px", padding: "20px", cursor: "pointer" }}
      />
      <img
        style={{
          height: "100px",
          padding: "15px",
          maxWidth: "calc(100% - 30px)",
        }}
        src={
          sessionId !== undefined
            ? process.env.REACT_APP_MEDIA_API_URL +
              "/session-expired/" +
              sessionId
            : process.env.REACT_APP_MEDIA_API_URL + "/session-not-found"
        }
        alt="badge"
      />
    </div>
  );
};
