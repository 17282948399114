import * as d3 from "d3";
import React, { useState } from "react";
import { RadarChart } from "../helper/RadarChart";
import { Language } from "../types";

export type PlayerTypesChartProps = {
  language: Language;
  data?: { axis: string; value: number }[][];
};

export const PlayerTypesChart: React.FC<PlayerTypesChartProps> = ({
  language,
  data,
}) => {
  const [badge, setBadge] = useState<string | undefined>(undefined);

  const [cardLoaded, setCardLoaded] = useState<boolean>(false);
  const sortedData: { axis: string; value: number }[][] = [];
  const color = d3.scaleOrdinal().range(["#EDC951", "#CC333F", "#00A0B0"]);

  const radarChartOptions = {
    maxValue: 0.5,
    levels: 5,
    roundStrokes: true,
    color: color,
  };

  const copyPlayerTypeProfileCode = () => {
    const el = document.createElement("textarea");
    el.value =
      "🎮:🔨" +
      sortedData[0][0].value +
      "🎒" +
      sortedData[0][1].value +
      "📔" +
      sortedData[0][2].value +
      "🏆" +
      sortedData[0][3].value +
      "💕" +
      sortedData[0][4].value +
      "🎁" +
      sortedData[0][5].value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  if (data !== undefined && data.length > 0) {
    data.forEach((dataItem) => {
      sortedData.push([
        {
          axis: "Disruptor",
          value:
            dataItem.find((type) => type?.axis === "Disruptor")?.value ?? 0,
        },
        {
          axis: "Free Spirit",
          value:
            dataItem.find((type) => type?.axis === "Free Spirit")?.value ?? 0,
        },
        {
          axis: "Achiever",
          value: dataItem.find((type) => type?.axis === "Achiever")?.value ?? 0,
        },
        {
          axis: "Player",
          value: dataItem.find((type) => type?.axis === "Player")?.value ?? 0,
        },
        {
          axis: "Socializer",
          value:
            dataItem.find((type) => type?.axis === "Socializer")?.value ?? 0,
        },
        {
          axis: "Philanthropist",
          value:
            dataItem.find((type) => type?.axis === "Philanthropist")?.value ??
            0,
        },
      ]);
    });

    setTimeout(() =>
      RadarChart(
        ".radar-chart",
        sortedData,
        radarChartOptions,
        (playerType: string) =>
          setBadge(playerType.toLowerCase().replace(" ", "-"))
      )
    );
  }

  const imageUrl =
    process.env.REACT_APP_MEDIA_API_URL +
    "/playertypes/" +
    badge +
    ".svg?lang=" +
    language;

  return (
    <>
      <div
        className="radar-chart"
        // height depends on copy-code-button height
        style={{ width: "100%", height: "calc(100% - 85px)", position: "relative", zIndex: 1 }}
      >
        {badge !== undefined && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              flexWrap: "wrap",
            }}
            onClick={() => {
              setBadge(undefined);
              setCardLoaded(false);
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                height: "fit-content",
                width: "fit-content",
              }}
            >
              <img
                alt="player-type-card"
                src={imageUrl}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                onLoad={() => setCardLoaded(true)}
              />
              {cardLoaded && (
                <img
                  alt="close"
                  src={process.env.PUBLIC_URL + "/close.svg"}
                  style={{
                    position: "absolute",
                    top: "2%",
                    right: "2%",
                    width: "10%",
                    height: "10%",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => copyPlayerTypeProfileCode()}
        className="copy-code-button"
        tabIndex={1}
      >
        🎮:🔨{sortedData[0][0].value}🎒{sortedData[0][1].value}📔
        {sortedData[0][2].value}🏆{sortedData[0][3].value}💕
        {sortedData[0][4].value}🎁{sortedData[0][5].value}
      </div>
    </>
  );
};
