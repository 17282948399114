import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

const API_URL =
  process.env.REACT_APP_API_URL ?? "https://dev.api.leanscope.io/graphql";

const httpLink = (uri: string) =>
  createHttpLink({
    uri,
  });

export const GraphQLClient = new ApolloClient({
  link: httpLink(API_URL),
  cache: new InMemoryCache(),
});
