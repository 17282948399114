export const personaBySessionIdQuery = (sessionId: string) => `
  query {
    personaBySession(personaSessionId: "${sessionId}"){
      persona { 
        _guid
        playerTypes {
          title
          weight
        }
      }
      projectId
      durationInSeconds
      startTime
    }
  }
`;
