import React, { useEffect, useRef, useState } from "react";
import { GraphQLClient } from "../api/graph-ql.client";
import { gql } from "@apollo/client/core";
import { accumulateSessionPersonaPlayerTypesMutation } from "../api/mutations/persona-playertypes";
import { playertypesFromTypeformQuery } from "../api/queries/playertypes-typeform";
import { Language, PlayerType } from "../types";

const typeformEmbed = require("@typeform/embed");

export type PlayerTypesSurveyProps = {
  language: Language;
  sessionId?: string;
  onSubmit: (playerTypes: PlayerType[]) => void;
  onError?: (error: Error) => void;
};

export const PlayerTypesSurvey: React.FC<PlayerTypesSurveyProps> = ({
  language,
  sessionId,
  onSubmit,
  onError,
}) => {
  const typeformRef = useRef(null);
  const [forceKill, setForceKill] = useState(false);

  const playerTypeFormUid =
    language === Language.DE
      ? process.env.REACT_APP_TYPEFORM_ID_DE!
      : process.env.REACT_APP_TYPEFORM_ID_EN!;

  useEffect(() => {
    typeformEmbed.makeWidget(
      forceKill ? null : typeformRef.current,
      "https://form.typeform.com/to/" + playerTypeFormUid,
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 1,
        onSubmit: async function (event: any) {
          document.querySelector("iframe")?.remove();
          setForceKill(true);
          const typeformResponseId = event.response_id;
          console.log("Typeform successfully submitted ", typeformResponseId);

          if (sessionId !== undefined) {
            mutateAndRequestPlayertypes(
              playerTypeFormUid,
              typeformResponseId,
              sessionId,
              onSubmit,
              onError
            );
          } else {
            requestPlayertypes(
              playerTypeFormUid,
              typeformResponseId,
              onSubmit,
              onError
            );
          }
        },
        onReady: function () {
          console.log("Typeform is ready");
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeformRef]);

  return forceKill ? null : (
    <div
      ref={typeformRef}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: "0",
        top: "0",
        right: "0",
        bottom: "0",
        background: "white",
      }}
    ></div>
  );
};

const mutateAndRequestPlayertypes = async (
  playerTypeFormUid: string,
  typeformResponseId: any,
  sessionId: string,
  onSubmit: (playerTypes: PlayerType[]) => void,
  onError?: (error: Error) => void
) => {
  try {
    const playerTypesResponse = await GraphQLClient.mutate<PlayerType[]>({
      mutation: gql(
        accumulateSessionPersonaPlayerTypesMutation(
          playerTypeFormUid,
          typeformResponseId,
          sessionId
        )
      ),
    });
    const playerTypes: PlayerType[] =
      (playerTypesResponse?.data as any)
        ?.accumulateSessionPersonaPlayerTypesWithTypformResponse ?? [];

    onSubmit(playerTypes);
  } catch (error) {
    console.error(error);
    onError && onError(error);
  }
};

const requestPlayertypes = async (
  playerTypeFormUid: string,
  typeformResponseId: any,
  onSubmit: (playerTypes: PlayerType[]) => void,
  onError?: (error: Error) => void
) => {
  try {
    const playerTypesResponse = await GraphQLClient.query<PlayerType[]>({
      query: gql(
        playertypesFromTypeformQuery(playerTypeFormUid, typeformResponseId)
      ),
    });
    const playerTypes: PlayerType[] =
      (playerTypesResponse?.data as any)?.getPlayerTypesFromTypeformResponse ??
      [];

    onSubmit(playerTypes);
  } catch (error) {
    console.error(error);
    onError && onError(error);
  }
};
