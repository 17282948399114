/////////////////////////////////////////////////////////
/////////////// The Radar Chart Function ////////////////
/////////////// Written by Nadieh Bremer ////////////////
////////////////// VisualCinnamon.com ///////////////////
/////////// Inspired by the code of alangrafu ///////////
/////////////////////////////////////////////////////////
import * as d3 from "d3";

export function RadarChart(id, data, options, setPlayerType) {
  const cfg = {
    w: 260, // Width of the circle
    h: 260, // Height of the circle
    margin: { top: 80, right: 80, bottom: 80, left: 80 }, // The margins of the SVG
    levels: 3, // How many levels or inner circles should there be drawn
    maxValue: 0, // What is the value that the biggest circle will represent
    labelFactor: 1.25, // How much farther than the radius of the outer circle should the labels be placed
    wrapWidth: 70, // The number of pixels after which a label needs to be given a new line
    opacityArea: 0.35, // The opacity of the area of the blob
    dotRadius: 6, // The size of the colored circles of each blog
    opacityCircles: 0.1, // The opacity of the circles of each blob
    strokeWidth: 2, // The width of the stroke around each blob
    roundStrokes: false, // If true the area and stroke will follow a round path (cardinal-closed)
    color: d3.scaleOrdinal(d3.schemeCategory10), // Color function
  };

  // Put all of the options into a variable called cfg
  if ("undefined" !== typeof options) {
    for (const i in options) {
      if ("undefined" !== typeof options[i]) {
        cfg[i] = options[i];
      }
    } // for i
  } // if

  // If the supplied maxValue is smaller than the actual one, replace by the max in the data
  const maxValue = Math.max(
    cfg.maxValue,
    d3.max(data, (i) => {
      return d3.max(
        i.map((o) => {
          return o.value;
        })
      );
    })
  );

  const allAxis = data[0].map((i, j) => {
    return i.axis;
  }); // Names of each axis
  const total = allAxis.length; // The number of different axes
  const radius = Math.min(cfg.w / 2, cfg.h / 2); // Radius of the outermost circle
  // const Format = d3.format('.0f'); // Percentage formatting
  const angleSlice = (Math.PI * 2) / total; // The width in radians of each "slice"

  // Scale for the radius
  const rScale = d3.scaleLinear().range([0, radius]).domain([0, maxValue]);

  /////////////////////////////////////////////////////////
  //////////// Create the container SVG and g /////////////
  /////////////////////////////////////////////////////////

  // Remove whatever chart with the same id/class was present before
  d3.select(id).select("svg").remove();

  // Initiate the radar chart SVG
  const svg = d3
    .select(id)
    .append("svg")
    .attr('viewBox', "0 0 " + (2 * (cfg.w / 2 + cfg.margin.left)) + " " + (2 * (cfg.h / 2 + cfg.margin.top)))
    .attr('width', '100%')
    .attr('height', '100%')
    .attr("class", "radar" + id);
  // Append a g element
  const g = svg
    .append("g")
    .attr(
      "transform",
      "translate(" +
        (cfg.w / 2 + cfg.margin.left) +
        "," +
        (cfg.h / 2 + cfg.margin.top) +
        ")"
    );

  /////////////////////////////////////////////////////////
  ////////// Glow filter for some extra pizzazz ///////////
  /////////////////////////////////////////////////////////

  // Filter for the outside glow
  const filter = g.append("defs").append("filter").attr("id", "glow");
  filter
    .append("feGaussianBlur")
    .attr("stdDeviation", "2.5")
    .attr("result", "coloredBlur");
  const feMerge = filter.append("feMerge");
  feMerge.append("feMergeNode").attr("in", "coloredBlur");
  feMerge.append("feMergeNode").attr("in", "SourceGraphic");

  /////////////////////////////////////////////////////////
  /////////////// Draw the Circular grid //////////////////
  /////////////////////////////////////////////////////////

  // Wrapper for the grid & axes
  const axisGrid = g.append("g").attr("class", "axisWrapper");

  // Draw the background circles
  axisGrid
    .selectAll(".levels")
    .data(d3.range(1, cfg.levels + 1).reverse())
    .enter()
    .append("circle")
    .attr("class", "gridCircle")
    .attr("r", (d, i) => {
      return (radius / cfg.levels) * d;
    })
    .style("fill", "#e5d2b2")
    .style("stroke", "#e5d2b2")
    .style("fill-opacity", cfg.opacityCircles)
    .style("filter", "url(#glow)");

  /////////////////////////////////////////////////////////
  //////////////////// Draw the axes //////////////////////
  /////////////////////////////////////////////////////////

  // Create the straight lines radiating outward from the center
  const axis = axisGrid
    .selectAll(".axis")
    .data(allAxis)
    .enter()
    .append("g")
    .attr("class", "axis");
  // Append the lines
  axis
    .append("line")
    .attr("x1", 0)
    .attr("y1", 0)
    .attr("x2", (d, i) => {
      return rScale(maxValue * 1.1) * Math.cos(angleSlice * i - Math.PI / 2);
    })
    .attr("y2", (d, i) => {
      return rScale(maxValue * 1.1) * Math.sin(angleSlice * i - Math.PI / 2);
    })
    .attr("class", "line")
    .style("stroke", "white")
    .style("stroke-width", "2px");

  // Append the labels at each axis
  axis
    .append("image")
    .attr("xlink:href", (d) => {
      return (
        process.env.PUBLIC_URL +
        "/player-type/" +
        d.toLowerCase().replace(" ", "-") +
        ".svg"
      );
    })
    .attr("width", 32)
    .attr("height", 32)
    .style("cursor", "pointer")
    .on("click", (e, d) => {
      // console.log(d);
      setPlayerType(d);
    })
    .attr("x", (d, i) => {
      return (
        rScale(maxValue * cfg.labelFactor) *
          Math.cos(angleSlice * i - Math.PI / 2) -
        16
      );
    })
    .attr("y", (d, i) => {
      return (
        rScale(maxValue * cfg.labelFactor) *
          Math.sin(angleSlice * i - Math.PI / 2) -
        24
      );
    });
  axis
    .append("text")
    .attr("class", "legend")
    .on("click", (e, d) => {
      // console.log(d);
      setPlayerType(d);
    })
    .style("font-size", "11px")
    .style("cursor", "pointer")
    .attr("text-anchor", "middle")
    .attr("dy", "0.35em")
    .attr("x", (d, i) => {
      return (
        rScale(maxValue * cfg.labelFactor) *
        Math.cos(angleSlice * i - Math.PI / 2)
      );
    })
    .attr("y", (d, i) => {
      return (
        rScale(maxValue * cfg.labelFactor) *
          Math.sin(angleSlice * i - Math.PI / 2) +
        14
      );
    })
    .text((d) => {
      return d;
    })
    .call(wrap, cfg.wrapWidth);

  /////////////////////////////////////////////////////////
  ///////////// Draw the radar chart blobs ////////////////
  /////////////////////////////////////////////////////////

  // The radial line function
  const radarLine = d3
    .lineRadial()
    .curve(d3.curveCardinalClosed)
    // .interpolate('linear-closed')
    .radius((d) => {
      return rScale(d.value);
    })
    .angle((d, i) => {
      return i * angleSlice;
    });

  if (cfg.roundStrokes) {
    // radarLine.interpolate('cardinal-closed');
  }

  // Create a wrapper for the blobs
  const blobWrapper = g
    .selectAll(".radarWrapper")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "radarWrapper");

  // Append the backgrounds
  blobWrapper
    .append("path")
    .attr("class", "radarArea")
    .attr("d", (d, i) => {
      return radarLine(d);
    })
    .style("fill", (d, i) => {
      return cfg.color(i);
    })
    .style("fill-opacity", cfg.opacityArea)

    // .on("mouseover", function (d, i) {
    //   // Dim all blobs
    //   d3.selectAll(".radarArea")
    //     .transition()
    //     .duration(200)
    //     .style("fill-opacity", 0.1);
    //   // Bring back the hovered over blob
    //   d3.select(this).transition().duration(200).style("fill-opacity", 0.7);
    // })
    // .on("mouseout", () => {
    //   // Bring back all blobs
    //   d3.selectAll(".radarArea")
    //     .transition()
    //     .duration(200)
    //     .style("fill-opacity", cfg.opacityArea);
    // });

  // Create the outlines
  blobWrapper
    .append("path")
    .attr("class", "radarStroke")
    .attr("d", (d, i) => {
      return radarLine(d);
    })
    .style("stroke-width", cfg.strokeWidth + "px")
    .style("stroke", (d, i) => {
      return "rgb(205 70 70)";
    })
    .style("fill", "none");
  // .style('filter', 'url(#glow)');

  // Append the circles
  blobWrapper
    .selectAll(".radarCircle")
    .data((d, i) => {
      return d;
    })
    .enter()
    .append("circle")
    .attr("class", "radarCircle")
    .attr("r", cfg.dotRadius)
    .attr("cx", (d, i) => {
      return rScale(d.value) * Math.cos(angleSlice * i - Math.PI / 2);
    })
    .attr("cy", (d, i) => {
      return rScale(d.value) * Math.sin(angleSlice * i - Math.PI / 2);
    })
    .style("fill", (d, i, j) => {
      return cfg.color(j);
    })
    .style("fill-opacity", 0.8);

  /////////////////////////////////////////////////////////
  //////// Append invisible circles for tooltip ///////////
  /////////////////////////////////////////////////////////

  // Wrapper for the invisible circles on top
  const blobCircleWrapper = g
    .selectAll(".radarCircleWrapper")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "radarCircleWrapper");

  // Append a set of invisible circles on top for the mouseover pop-up
  blobCircleWrapper
    .selectAll(".radarInvisibleCircle")
    .data((d, i) => {
      return d;
    })
    .enter()
    .append("circle")
    .attr("class", "radarInvisibleCircle")
    .attr("r", cfg.dotRadius * 1.5)
    .attr("cx", (d, i) => {
      return rScale(d.value) * Math.cos(angleSlice * i - Math.PI / 2);
    })
    .attr("cy", (d, i) => {
      return rScale(d.value) * Math.sin(angleSlice * i - Math.PI / 2);
    })
    .style("fill", "none")
    .style("pointer-events", "all")
    .style("cursor", "pointer")
    .on("click", (e, d) => {
      console.log("click");
      setPlayerType(d.axis);
    })
    // .on("mouseover", function (e, d, i, node) {
    //   /* console.log(d);
    //   console.log(i);
    //   console.log(node); */
    //   const newX = parseFloat(d3.select(this).attr("cx")) - 10;
    //   const newY = parseFloat(d3.select(this).attr("cy")) - 10;

    //   tooltip
    //     .attr("x", newX)
    //     .attr("y", newY)
    //     .text(d.axis + ": " + d.value)
    //     .transition()
    //     .duration(200)
    //     .style("opacity", 1);
    // })
    // .on("mouseout", () => {
    //   tooltip.transition().duration(200).style("opacity", 0);
    // });

  // Set up the small tooltip for when you hover over a circle
  // const tooltip = g.append("text").attr("class", "tooltip").style("opacity", 0);

  /////////////////////////////////////////////////////////
  /////////////////// Helper Function /////////////////////
  /////////////////////////////////////////////////////////

  // Taken from http://bl.ocks.org/mbostock/7555321
  // Wraps SVG text
  function wrap(textItem, width) {
    textItem.each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.4; // ems
      const y = text.attr("y");
      const x = text.attr("x");
      const dy = parseFloat(text.attr("dy"));
      let tspan = text
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  } // wrap
} // RadarChart
